<template>
    <div>
        <header-v2-comp id="header-v2-auth" />
        <!-- Initiate Routing Child components -->
        <router-view />
    </div>
</template>

<script>
import HeaderV2Comp from '../../containers/HeaderV2'
export default {
    components: {
        HeaderV2Comp
    },
}
</script>

<style scoped>
@media (max-width: 767px) {
    #header-v2-auth {
        display: none;
    }
}
</style>