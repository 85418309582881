<template>
    <div id="top-line-header">
        <div class="container">
            <div v-if="!currentCustomer" class="text-right">
                <span>
                    <router-link to="/auth/login" class="text-nav-muted">Login</router-link>
                </span>
                <span></span>
                <span class="ml-3">
                    <router-link to="/auth/signup" class="text-nav-muted">Sign Up</router-link>
                </span>
            </div>
            <div v-else class="text-right">
                <span>
                    <router-link to="/customer/dashboard" class="text-nav-muted">My Dashboard</router-link>
                </span>
                <span></span>
                <span class="ml-3 pointer">
                    <a href="#" class="text-nav-muted" @click="signOut">Log Out</a>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
    computed: {
        ...mapGetters(['currentCustomer']),
    },
    methods: {
        ...mapActions(['signOut']),
    }
}
</script>

<style scoped>
#top-line-header {
    background: #A91A18;
    width: 100%;
    padding: 7px 0px;
    color: #ffffff;
}

a.text-nav-muted {
    color: #ffffff;
    text-decoration: unset;
}
</style>