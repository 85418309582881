<template>
  <div id="header-v2" class="">
    <!-- <top-line-header-comp /> -->
    <!-- <div class="container" v-if="!showonly"> -->
    <div class="" v-if="!showonly">
      <nav
        class="navbar navbar-expand-lg navbar-light d-md-flex d-sm-none d-none"
      >
        <div
          class="
            navbar-left 
            order-1 order-md-0
            dual-collapse2
            text-center
          "
          style="width: 200px;"
        >
          <div class="navbar-brand mr-auto">
            <router-link to="/" class="">
              <img
                src="/img/logo_selcare_small.png"
                height="45"
                alt=""
                loading="lazy"
              />
            </router-link>
          </div>
        </div>
        <div class="navbar-collapse collapse mx-auto order-0 middle-nav">
          <ul
            class="navbar-nav mx-auto small fs-responsive"
            style="font-weight: bold"
          >
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/home-nursing/booking' }"
            >
              <router-link class="nav-link" to="/home-nursing/booking"
                >Home Nursing</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a class="nav-link" href="#">Pharmacy</a>
              <!-- <a v-if="!currentCustomer" class="nav-link" target="_blank" href="https://jovianselcare.com/">Pharmacy</a>
              <router-link v-else class="nav-link" to="/customer/jovianselcare-generate-token">Pharmacy</router-link> -->
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: ($route.path).includes('/e-prescription') }"
            >
              <!-- <router-link
                v-if="this.currentCustomer == null"
                class="nav-link"
                to="/auth/login"
                >Telemedicine /<br />
                E-prescription</router-link
              > -->
              <!-- <router-link class="nav-link" to="/e-prescription/checking"
                >Telemedicine /<br />
                E-prescription</router-link
              > -->
              <router-link class="nav-link" to="/e-prescription/user-profile"
                >Telemedicine /<br />
                E-prescription</router-link
              >
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/clinic-appointment/manage' }"
            >
              <router-link
                class="nav-link"
                to="/clinic-appointment/manage"
                >Clinic/Dental<br />
                Appointment</router-link
              >
            </li>
            <li class="nav-item mr-1">
              <a class="nav-link" target="_blank" href="https://secure.selcare.my"
                >Selcare Corporate /<br />
                TPA</a
              >
            </li>
            <li
              class="nav-item mr-1"
              :class="{ active: $route.path == '/clinic-appointment/manage' }"
            >
              <router-link
                class="nav-link"
                to="/ambulance-and-paramedic"
                >Ambulance /<br />
                Paramedic</router-link
              >
            </li>
          </ul>
          
          <div style="background: #fff; padding: 10px 5px; border-radius: 40px;" class="mr-3">
            <div class="btn-group">
              <span
                class="btn dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><i class="fas fa-user-circle"></i
              ></span>
              <div
                v-if="currentCustomer"
                class="dropdown-menu dropdown-menu-right"
              >
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/customer/dashboard')"
                >
                  My Dashboard
                </button>
                <button class="dropdown-item" type="button" @click="signOut">
                  Logout
                </button>
              </div>
              <div v-else class="dropdown-menu dropdown-menu-right">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/auth/login')"
                >
                  Log In
                </button>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/auth/signup')"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>

        </div>
        <!-- <div
          class="navbar-right order-3 dual-collapse2" style="width: 200px;"
        >
          <div class="navbar-nav ml-auto float-right">
            <div class="btn-group">
              <span
                class="btn dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                ><i class="fas fa-user-circle"></i
              ></span>
              <div
                v-if="currentCustomer"
                class="dropdown-menu dropdown-menu-right"
              >
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/customer/dashboard')"
                >
                  My Dashboard
                </button>
                <button class="dropdown-item" type="button" @click="signOut">
                  Logout
                </button>
              </div>
              <div v-else class="dropdown-menu dropdown-menu-right">
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/auth/login')"
                >
                  Log In
                </button>
                <button
                  class="dropdown-item"
                  type="button"
                  @click="$router.push('/auth/signup')"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div> -->
      </nav>
      <!-- Inititate Mobile Nav -->
      <div style="padding:5px 20px 38px 20px; background-color: #a81a17; margin-bottom: 25px;" class="d-md-none d-sm-block d-block">
        <nav
          id="mobile-nav-header-v2"
          class="navbar navbar-light light-blue lighten-4"
        >
          <!-- Collapse button -->
          <button
            class="navbar-toggler toggler-example float-right"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent1"
            aria-controls="navbarSupportedContent1"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style="position: absolute; right: 0px;border: unset"
          >
            <span class="dark-blue-text" style="font-size: 25px;color: #ffffff;"><i class="fas fa-bars fa-1x"></i></span>
          </button>
          <!-- Collapsible content -->
          <div class="collapse navbar-collapse" id="navbarSupportedContent1">
            <!-- Links -->
            <ul class="navbar-nav mr-auto">
              <li class="nav-item my-1" :class="{ active: $route.path == '/' }">
                <router-link
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  to="/"
                  >Home</router-link
                >
              </li>
              <li
                class="nav-item my-1"
                :class="{ active: $route.path.includes('/home-nursing/booking') }"
              >
                <router-link
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  to="/home-nursing/booking"
                  >Home Nursing</router-link
                >
              </li>
              <li class="nav-item mr-1  my-1">
                <a
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  href="#"
                >Pharmacy</a>
                <!-- <a
                  v-if="!currentCustomer"
                  target="_blank"
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  href="https://jovianselcare.com/"
                >Pharmacy</a>
                <router-link
                  v-else
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  to="/customer/jovianselcare-generate-token"
                >Pharmacy</router-link> -->
              </li>
              <li
                class="nav-item mr-1 my-1"
                :class="{ active: $route.path.includes('/e-prescription') }"
              >
                <!-- <router-link
                  v-if="this.currentCustomer == null"
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  to="/auth/login"
                  >Telemedicine /<br />
                  E-prescription</router-link
                > -->
                <router-link
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  to="/e-prescription/user-profile"
                  >Telemedicine / E-prescription</router-link
                >
              </li>
              <li
                class="nav-item mr-1 my-1"
                :class="{ active: $route.path == '/clinic-appointment/manage' }"
              >
                <!-- <router-link
                  class="nav-link"
                  v-if="this.currentCustomer == null"
                  to="/auth/login"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  >Clinic Dental / <br />Appointment</router-link
                >
                <router-link
                  v-else
                  class="nav-link"
                  to="/clinic-appointment/manage"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  >Clinic Dental / <br />Appointment</router-link
                > -->
                <router-link
                  class="nav-link"
                  to="/clinic-appointment/manage"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  >Clinic Dental / Appointment</router-link
                >
              </li>
              <li class="nav-item mr-1 my-1">
                <a class="nav-link">Selcare Corporate / TPA</a>
              </li>
              <li
                class="nav-item mr-1 my-1"
                :class="{ active: $route.path == '/ambulance-and-paramedic' }"
              >
                <!-- <router-link
                  class="nav-link"
                  v-if="this.currentCustomer == null"
                  to="/auth/login"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  >Clinic Dental / <br />Appointment</router-link
                >
                <router-link
                  v-else
                  class="nav-link"
                  to="/clinic-appointment/manage"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  >Clinic Dental / <br />Appointment</router-link
                > -->
                <router-link
                  class="nav-link"
                  to="/ambulance-and-paramedic"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  >Ambulance / Paramedic</router-link
                >
              </li>
              <li class="nav-item my-1">
                <a
                  class="nav-link"
                  href="https://selgatecorporation.com/healthcare/"
                  >About Us</a
                >
              </li>
              <li class="nav-item my-1 pb-2">
                <a
                  class="nav-link"
                  href="https://selgatecorporation.com/contact-us/"
                  >Contact Us</a
                >
              </li>
            </ul>
            <ul class="navbar-nav mr-auto pt-2" v-if="currentCustomer">
              <li class="nav-item">
                <span
                  class="nav-link"
                  @click="$router.push('/customer/dashboard')"
                  >My Dashboard</span
                >
              </li>
              <li class="nav-item" style="border: unset;">
                <span class="nav-link" @click="signOut">Logout</span>
              </li>
            </ul>
            <ul class="navbar-nav mr-auto pt-2" v-else>
              <li class="nav-item" :class="{ active: $route.path == '/' }">
                <router-link
                  class="nav-link"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent1"
                  to="/"
                  >Home</router-link
                >
              </li>
              <li class="nav-item">
                <span class="nav-link" @click="$router.push('/auth/login')"
                  >Log In</span
                >
              </li>
              <li class="nav-item" style="border: unset;">
                <span class="nav-link" @click="$router.push('/auth/signup')"
                  >Sign Up</span
                >
              </li>
            </ul>
            <!-- Links -->
          </div>
          <!-- Collapsible content -->
        </nav>
      </div>
    </div>
    <div v-else-if="showonly && showonly == 'invoice'" class="pt-5"></div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TopLineHeaderComp from "./TopLineHeader";

export default {
  props: ["showonly"],
  components: {
    TopLineHeaderComp,
  },
  computed: {
    ...mapGetters(["currentCustomer"]),
  },
  methods: {
    ...mapActions(["signOut"]),
  },
};
</script>

<style scoped>
nav {
  padding: 30px 30px;
  font-family: "Karla", serif;
  padding: 0px 0px !important;
}
nav span {
  font-size: 20px;
  color: #000000;
  text-transform: initial;
  font-weight: bold;
}
nav span.nav-link {
  font-size: 14px;
  text-transform: uppercase;
}

nav .nav-item.badge-blue {
  border: 1px solid #b5d4f1;
  border-radius: 15px;
  background-color: #b5d4f1;
  padding: 0px 5px;
  margin-left: 5px;
  color: #2c659a;
}
nav .nav-item.badge-blue a {
  color: #2c659a;
}

nav#mobile-nav-header-v2 {
  border-bottom: unset !important;
}
nav#mobile-nav-header-v2 #navbarSupportedContent1 li.nav-item:last-child {
  padding-top: 0px;
  border-bottom: 1px solid rgb(165 157 157 / 16%);
}
nav#mobile-nav-header-v2 .navbar-nav .nav-link{
  color: #ce7a79;
  font-size: 12px;
}
nav#mobile-nav-header-v2 .navbar-nav .active .nav-link{
  color: #fff;
}
#navbarSupportedContent1 {
  margin-top: 50px;
}

.middle-nav {
  background: #a91a17;
  padding: 15px 10px;
  border-radius: 0px;
}
.middle-nav .nav-link {
  color: #fff;
}

.middle-nav ul {
  align-items: center;
  text-align: center;
}

.middle-nav li {
  padding: 0px 10px;
}

.middle-nav li.nav-item.active::after {
    content: '';
    border-bottom: 3px solid #fff;
    width: 70%;
    display: block;
    margin: 0 auto;
    border-radius: 10px;
}

/* // Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  #mobile-nav-header-v2 {
    display: block;
  }
}

/* // Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  #mobile-nav-header-v2 {
    display: block;
  }

  .fs-responsive {
    font-size: 0.7rem !important;
    /* font-size: 0.47rem !important; */
  }
}

/* // Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  #mobile-nav-header-v2 {
    display: block;
  }
  .fs-responsive {
    font-size: 0.7rem !important;
    /* font-size: 0.47rem !important; */
  }
}

/* // Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  #mobile-nav-header-v2 {
    display: none;
  }
  .fs-responsive {
    font-size: 0.4rem !important;
    /* font-size: 0.47rem !important; */
  }
}

/* // Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  #mobile-nav-header-v2 {
    display: none;
  }
  .fs-responsive {
    font-size: 0.7rem !important;
  }
}
</style>